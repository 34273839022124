import {Address} from "./Address.js";

export class Customer {
    /**
     *
     * @param firstName {string}
     * @param lastName {string}
     * @param email {string}
     * @param phoneNumber {string}
     * @param billingAddress {Address}
     * @param shippingAddress {Address}
     */
    constructor(firstName, lastName, email, phoneNumber, billingAddress, shippingAddress) {
        this._firstName = firstName;
        this._lastName = lastName;
        this._email = email;
        this._phoneNumber = phoneNumber;
        this._billingAddress = billingAddress;
        this._shippingAddress = shippingAddress;
    }

    /**
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * @param value {string}
     */
    set firstName(value) {
        this._firstName = value;
    }

    /**
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * @param value {string}
     */
    set lastName(value) {
        this._lastName = value;
    }

    /**
     * @returns {string}
     */
    get email() {
        return this._email;
    }

    /**
     * @param value {string}
     */
    set email(value) {
        this._email = value;
    }

    /**
     * @returns {string}
     */
    get phoneNumber() {
        return this._phoneNumber;
    }

    /**
     * @param value {string}
     */
    set phoneNumber(value) {
        this._phoneNumber = value;
    }

    /**
     * @returns {Address}
     */
    get billingAddress() {
        return this._billingAddress;
    }

    /**
     * @param value {Address}
     */
    set billingAddress(value) {
        this._billingAddress = value;
    }

    /**
     * @returns {Address}
     */
    get shippingAddress() {
        return this._shippingAddress;
    }

    /**
     * @param value {Address}
     */
    set shippingAddress(value) {
        this._shippingAddress = value;
    }

    serialize() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            billingAddress: this.billingAddress ? this.billingAddress.serialize() : null,
            shippingAddress: this.shippingAddress ? this.shippingAddress.serialize() : null
        };
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        const firstName = value.firstName ? value.firstName : null;
        const lastName = value.lastName ? value.lastName : null;
        const email = value.email ? value.email : null;
        const phoneNumber = value.phoneNumber ? value.phoneNumber : null;
        const billingAddress = value.billingAddress ? Address.fromJson(value.billingAddress) : null;
        const shippingAddress = value.shippingAddress ? Address.fromJson(value.shippingAddress) : null;
        return new Customer(firstName, lastName, email, phoneNumber, billingAddress, shippingAddress);
    }
}