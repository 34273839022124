import {Session} from "./Session.js";
import {EventType} from "./EventType.js";
import {AltaPayFactory} from "./AltaPayFactory.js";
import {CallbackType} from "./CallbackType.js";
import {PaymentStatus} from "./PaymentStatus.js";
import {AccessToken} from "./AccessToken.js";

/**
 * @implements {IAltaPay}
 */
export class AltaPay {

    altaPayFactory;
    paymentStatusCheckInterval = 1000;
    sessionToken;
    sessionId;
    order;
    context;
    callbacks;
    configuration;
    scheduledOperations;
    paymentMethods;
    selectedPaymentMethodId;
    paymentId;
    baseUrl;
    currentBrowser;
    initializeSessionPromise;
    onSelectedPaymentMethod = [];
    onRenderPaymentMethod;
    checkStatus;
    methodHandlers = [];

    constructor() {
        this.altaPayFactory = new AltaPayFactory();
        // we need to make sure we capture the top window origin and not the embedded iframe otherwise we're vetting for legitimacy of the parent website with our domain!
        // Thankfully ApplePay is not working because of it, but potentially more payment methods could be blocked because of different origin than expected.
        let origin = window.location.origin
        if (this.#loadedInIframe()) {
            const originOverride = this.#getParam("origin");
            if (originOverride) {
                origin = originOverride;
            } else {
                throw new Error('AltaPay library is loaded inside iframe without specifying "origin" parameter. Please refer to documentation for more information!');
            }
        }
        this.currentBrowser = this.altaPayFactory.getBrowser(navigator.userAgent, navigator.language, origin, screen.height, screen.width);
        this.context = this.altaPayFactory.getContext(this.currentBrowser);
        this.paymentMethods = new Map();
        this.scheduledOperations = [];
    }

    init(sessionToken, sessionId) {
        this.reset();
        return this.#initInternal(sessionToken, sessionId);
    }

    initFromQR(qrCodeData) {
        this.reset();
        const parsedQRCodeData = JSON.parse(atob(qrCodeData));

        if (!parsedQRCodeData || !parsedQRCodeData.apiUrl) {
            throw new Error('QR code data is not valid. Please check the QR code and try again.');
        }
        this.baseUrl = parsedQRCodeData.apiUrl;

        this.initializeSessionPromise = fetch(this.baseUrl + '/v1/api/link/' + parsedQRCodeData.id, {
            method: 'GET'
        }).then(function (response) {
            console.log('response:', response);
            return response.json();
        }).then((data) => {
            console.log('Success:', data);
            if (data && data.sessionId && data.token) {
                this.sessionId = data.sessionId;
                this.sessionToken = data.token;
                return Promise.resolve();
            } else {
                return Promise.reject("Missing sessionId in response!")
            }
        }).then(() => {
            this.#initInternal(this.sessionToken, this.sessionId);
            return Promise.resolve();
        });
        return this;
    }

    reset() {
        this.closePayment();
        this.paymentMethods = new Map();
        this.scheduledOperations = [];
        this.paymentStatusCheckInterval = 1000;
        this.sessionToken = null;
        this.sessionId = null;
        this.order = null;
        this.callbacks = null;
        this.configuration = null;
        this.scheduledOperations = [];
        this.paymentMethods = new Map();
        this.selectedPaymentMethodId = null;
        this.paymentId = null;
        this.baseUrl = null;
        this.initializeSessionPromise = null;
        this.onSelectedPaymentMethod = [];
        this.onRenderPaymentMethod = null;
        this.methodHandlers = [];
        return this;
    }

    closePayment() {
        this.#resetCheckStatus();
        window.dispatchEvent(new CustomEvent('closeEmbeddedPaymentWindow'));
        return this;
    }

    #loadedInIframe() {
        return window.top !== window.self;
    }

    #initInternal(sessionToken, sessionId) {
        let authToken = sessionToken;
        if (!authToken) {
            authToken = this.#getCookie('authBearer');
            if (!authToken) {
                throw new Error('No token is provided nor present in the cookies. Please check the token and try again.');
            }
        }
        const parsedToken = this.#parseJwt(authToken);
        if (!parsedToken || !parsedToken.apiUrl) {
            throw new Error('Token is not valid. Please check the token and try again.');
        }
        if (!parsedToken.canBeUsedFromJsSdk()) {
            throw new Error('Only one-time-use customer facing tokens can be used with the JS SDK.');
        }

        document.cookie = "authBearer=" + authToken + ";path=/";
        this.baseUrl = parsedToken.apiUrl;
        this.sessionToken = authToken;
        if (!sessionId) {
            this.initializeSessionPromise = this.#createNewSession();
        } else {
            this.initializeSessionPromise = this.#loadSession(sessionId);
        }
        return this;
    }

    #resetCheckStatus() {
        if (this.checkStatus) {
            clearInterval(this.checkStatus);
            this.checkStatus = null;
        }
    }


    /**
     *
     * @param {string} token
     * @returns {AccessToken}
     */
    #parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return AccessToken.fromJson(JSON.parse(decodeURIComponent(window.atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')))
        );
    }

    #getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return undefined;
    }

    #getParam(paramName) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(paramName);
    }

    #handleJSError(error) {
        console.error(error);
        if (this.callbacks && typeof this.callbacks.failure.value === 'function') {
            const shopOrderId = this.order ? this.order.orderId : null;
            this.callbacks.failure.value(this.altaPayFactory.getAltaPayEvent(EventType.RENDER, this.paymentId, shopOrderId, PaymentStatus.ERROR, error));
        }
    }

    #createNewSession() {
        return fetch(this.baseUrl + '/v1/api/session', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.sessionToken,
                'Content-Type': 'application/json',
            },
        }).then(function (response) {
            console.debug('create-session response:', response);
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
        }).then((data) => {
            if (data && data.sessionId) {
                this.sessionId = data.sessionId;
                return Promise.resolve();
            } else {
                return Promise.reject("Missing sessionId in response!")
            }
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    #loadSession(sessionId) {
        this.sessionId = sessionId;
        return fetch(this.baseUrl + '/v1/api/session/' + this.sessionId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.sessionToken,
            },
        }).then(function (response) {
            console.debug('get-session response:', response);
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
        }).then((data) => {
            if (data) {
                const session = Session.fromJson(data);
                this.order = session.order;
                this.configuration = session.configuration;
                this.context = session.context;
                // update browser information with current browser used by the user
                this.context.browser = this.currentBrowser;
                return Promise.resolve();
            } else {
                return Promise.reject("Invalid session data");
            }
        })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    #scheduleOperation(func) {
        this.scheduledOperations.push(func);
    }

    setOrder(order) {
        const that = this;
        this.#scheduleOperation(function () {
            that.order = order;
            return Promise.resolve();
        })
        return this;
    }

    setCallbacks(callbacks) {
        const that = this;
        this.#scheduleOperation(function () {
            that.callbacks = callbacks;
            return Promise.resolve();
        });
        return this;
    }

    setConfiguration(configuration) {
        const that = this;
        this.#scheduleOperation(function () {
            that.configuration = configuration;
            return Promise.resolve();
        });
        return this;
    }

    async handleRedirectData(redirectData) {
        return new Promise((resolve, reject) => {
            try {
                const decodedRedirectData = atob(redirectData);
                const decodedRedirectDataJson = JSON.parse(decodedRedirectData);

                if (decodedRedirectDataJson.paymentId) {
                    this.paymentId = decodedRedirectDataJson.paymentId;
                    this.#waitForSessionUpdated()
                        .then(() => {
                            this.#checkStatus();
                            return resolve();
                        })
                        .catch(error => {
                            const errorMessage = "Error handling redirected session: " + error;
                            this.#handleJSError(errorMessage);
                            reject(errorMessage);
                        });
                } else {
                    return reject('PaymentId not available');
                }
            } catch (error) {
                console.error("Invalid redirectData provided: " + redirectData, error);
                return reject("Invalid redirectData provided.");
            }
        });
    }

    #waitForSessionUpdated() {
        if (this.initializeSessionPromise === undefined) {
            return Promise.reject("Session not initialized. Please call IAltaPay::init() first.");
        }
        return this.initializeSessionPromise
            .then(() => this.#waitForScheduledOperationsToComplete())
            .then(() => {
                let session = new Session(this.sessionId, this.order, this.context, this.callbacks, this.configuration);
                return fetch(this.baseUrl + '/v1/api/session/' + this.sessionId, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + this.sessionToken,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(session.serialize())
                }).then((response) => {
                    console.debug('update-session response:', response);
                    if (response.status === 202) {
                    } else if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 401) {
                        throw new Error('Unauthorized');
                    } else {
                        return response.json().then(data => {
                            throw new Error(data.message);
                        });
                    }
                }).catch((error) => Promise.reject(error));
            });
    }

    #waitForScheduledOperationsToComplete() {
        //executes the scheduled operations in order (FIFO)
        if (this.scheduledOperations.length === 0)
            return Promise.resolve();
        let operation = this.scheduledOperations.shift();
        return operation()
            .then(() => this.#waitForScheduledOperationsToComplete());
    }

    async injectQR(parentHtmlElement, qrReturnUrl) {
        return new Promise((resolve, reject) => {
            this.#waitForSessionUpdated()
                .then(() => {
                    fetch(this.baseUrl + "/v1/api/session/" + (this.sessionId) + "/link", {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + this.sessionToken,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            url: qrReturnUrl
                        })
                    })
                        .then(function (response) {
                            if (response.status === 200) {
                                return response.json();
                            } else if (response.status === 401) {
                                throw new Error('Unauthorized');
                            } else {
                                return response.json().then(data => {
                                    throw new Error(data.message);
                                });
                            }
                        })
                        .then(qrResult => {
                            if (parentHtmlElement.nodeName === "IMG") {
                                parentHtmlElement.src = 'data:image/png;base64,' + qrResult.qrCode;
                                parentHtmlElement.style = "height:400px; max-height: 400px; max-width:400px; width: 400px;";
                            } else if (parentHtmlElement.nodeName === "DIV") {
                                let qrElement = document.createElement('img');
                                qrElement.className = "payment-qr-img";
                                qrElement.src = 'data:image/png;base64,' + qrResult.qrCode;
                                qrElement.style = "height:400px; max-height: 400px; max-width:400px; width: 400px;";
                                parentHtmlElement.appendChild(qrElement);
                            } else {
                                const errorMessage = "Unexpected HTMLElement provided: <" + parentHtmlElement.nodeName.toLowerCase() + ">. Expected <img> or <div> tag.";
                                this.#handleJSError(errorMessage);
                                reject(errorMessage);
                                return;
                            }
                            this.#checkStatusBySessionId();
                            resolve(this);
                        })
                        .catch(error => {
                            const errorMessage = "Error injecting QR code: " + error;
                            this.#handleJSError(errorMessage);
                            reject(errorMessage);
                        });
                })
                .catch(error => {
                    const errorMessage = "Error configuring the session: " + error;
                    this.#handleJSError(errorMessage);
                    reject(errorMessage);
                });
        });
    }

    async injectPaymentMethods(parentHtmlElement) {
        return new Promise((resolve, reject) => {
            this.#waitForSessionUpdated()
                .then(() => {
                    console.debug("Retrieving payment methods for session " + this.sessionId);
                    if (!this.sessionToken || !this.sessionId) {
                        reject("Session is not setup properly!");
                        return;
                    }
                    this.#getPaymentMethods()
                        .then((data) => {
                            //TODO: Use a templating system or alike
                            parentHtmlElement.replaceChildren(); // this is clearing everything below parent element
                            parentHtmlElement.style = parentHtmlElement.style + ";display: inline-grid;";
                            const that = this;

                            if (!data.methods || data.methods.length === 0) {
                                const errorMessage = "No payment methods available.";
                                this.#handleJSError(errorMessage);
                                reject(errorMessage);
                                return;
                            }
                            const validationPromises = [];
                            data.methods.forEach(function (method) {
                                that.paymentMethods.set(method.id, method);
                                const altapayPaymentMethodMetadata = method.metadata != null
                                    ? that.altaPayFactory.getPaymentMethodMetadata(
                                        method.metadata.terminalName,
                                        method.metadata.merchantId,
                                        method.metadata.canUseCredit,
                                        method.metadata.canIssueNewCredit
                                    )
                                    : null;
                                const altapayPaymentMethod = that.altaPayFactory.getPaymentMethod(
                                    method.id,
                                    method.description,
                                    method.logoUrl,
                                    altapayPaymentMethodMetadata,
                                    method.name
                                );
                                if (method.jsMethodHandler) {
                                    that.methodHandlers[method.id] = window.AltaPayPaymentMethodFactory[method.jsMethodHandler.identifier](
                                        that.sessionToken,
                                        that.sessionId,
                                        method.id,
                                        that.order,
                                        method.jsMethodHandler.data
                                    );
                                }
                                if (method.onRenderCheck) {
                                    if (method.onRenderCheck.type === 'SCRIPT') {
                                        validationPromises.push(
                                            that.methodHandlers[method.id].onRenderCheck()
                                                .then(
                                                    () => Promise.resolve(altapayPaymentMethod),
                                                    () => {
                                                        console.info("Payment method [" + method.description + "] not available on customer device.");
                                                        return Promise.reject();
                                                    }));
                                    }
                                } else {
                                    validationPromises.push(Promise.resolve(altapayPaymentMethod));
                                }
                            }, Altapay);

                            Promise.allSettled(validationPromises)
                                .then(() => {
                                    validationPromises.forEach(validationResult => {
                                        validationResult.then(altapayPaymentMethod => {
                                            that.#renderPaymentMethod(altapayPaymentMethod, parentHtmlElement);
                                        }).catch(() => {
                                            // noop
                                        });
                                    })
                                })
                                .then(() => {
                                    console.debug('Payment method Success:', data);
                                    resolve(that);
                                });
                        })
                        .catch(error => {
                            const errorMessage = "Error injecting payment methods: " + error;
                            this.#handleJSError(errorMessage);
                            reject(errorMessage);
                        });
                })
                .catch(error => {
                    const errorMessage = "Error configuring the session: " + error;
                    this.#handleJSError(errorMessage);
                    reject(errorMessage);
                });
        });
    }

    #getPaymentMethods() {
        return fetch(this.baseUrl + '/v1/api/session/' + this.sessionId + "/payment-methods", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.sessionToken,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            console.debug('get-payment-methods:', response);
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 401) {
                throw new Error('Unauthorized');
            } else {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
        });
    }

    #renderPaymentMethod(altapayPaymentMethod, parentHtmlElement) {
        let paymentMethodElement = this.#getDefaultPaymentMethodElement(altapayPaymentMethod);
        if (this.onRenderPaymentMethod) {
            const onMethodSelected = function () {
                this.onSelectPaymentMethod(altapayPaymentMethod.id);
            }.bind(this);
            const onSelectPaymentMethodEvent = this.altaPayFactory.getOnSelectPaymentMethodEvent(
                altapayPaymentMethod,
                paymentMethodElement,
                onMethodSelected
            );
            paymentMethodElement = this.onRenderPaymentMethod(onSelectPaymentMethodEvent);
        }

        if (paymentMethodElement) {
            parentHtmlElement.appendChild(paymentMethodElement);
        }
    }

    #getDefaultPaymentMethodElement(method) {
        let paymentMethodElement = document.createElement('div');
        paymentMethodElement.className = "payment-method-element";
        paymentMethodElement.id = method.id;

        let paymentMethodLogoElement = document.createElement('div');
        paymentMethodLogoElement.id = method.id + '-logo';
        paymentMethodLogoElement.className = 'payment-method-logo';
        paymentMethodLogoElement.style.float = 'left';
        paymentMethodLogoElement.style.opacity = '0.5';
        paymentMethodLogoElement.innerHTML = "<img alt=\"" + method.description + "\" src=\"" + method.logoUrl + "\" width=\"30px\" height=\"30px\">";
        paymentMethodElement.appendChild(paymentMethodLogoElement);

        let paymentMethodDescriptionElement = document.createElement('div');
        paymentMethodDescriptionElement.id = 'payment-method-' + method.description;
        paymentMethodDescriptionElement.class = 'payment-method-description';
        paymentMethodDescriptionElement.style.float = 'left';
        paymentMethodDescriptionElement.innerHTML = "<p>" + method.description + "</p>";
        paymentMethodElement.appendChild(paymentMethodDescriptionElement);

        paymentMethodElement.onclick = this.#onDefaultPaymentMethodSelectBehavior.bind(this);
        paymentMethodElement.ontouchstart = this.#onDefaultPaymentMethodSelectBehavior.bind(this);

        return paymentMethodElement;
    }

    #onDefaultPaymentMethodSelectBehavior(event) {
        const clickedPaymentMethodElement = event.currentTarget;
        const paymentMethodElements = document.querySelectorAll('.payment-method-element');
        for (let i = 0; i < paymentMethodElements.length; i++) {
            const currentPaymentMethod = paymentMethodElements[i];
            if (currentPaymentMethod !== clickedPaymentMethodElement) {
                currentPaymentMethod.querySelector('.payment-method-logo').style.opacity = '0.5';
            }
        }
        clickedPaymentMethodElement.querySelector('.payment-method-logo').style.opacity = '1';
        this.onSelectPaymentMethod(clickedPaymentMethodElement.id)
    }

    onSelectPaymentMethod(selectedPaymentMethodId) {
        this.selectedPaymentMethodId = selectedPaymentMethodId;
        this.onSelectedPaymentMethod.forEach(callback => callback.call());
    }

    async initiatePayment(config) {
        this.#resetCheckStatus();
        this.closePayment();
        const { paymentMethodId, paymentDisplayComponent } = this.#parseInitiatePaymentParams(config) || {};
        return new Promise((resolve, reject) => {
            const methodSelected = paymentMethodId ? paymentMethodId : this.selectedPaymentMethodId;
            const componentSelected = paymentDisplayComponent ? paymentDisplayComponent : this.configuration.paymentDisplayComponent;
            if (!this.sessionToken || !this.sessionId || !methodSelected) {
                reject("Payment method not selected!");
                return;
            }
            const paymentMethod = this.paymentMethods.get(methodSelected);

            if (paymentMethod.onInitiatePayment && paymentMethod.onInitiatePayment.type === 'SCRIPT') {
                this.#checkStatusBySessionId();
                this.methodHandlers[paymentMethod.id].onInitiatePayment();
                resolve(this);
            } else if (paymentMethod.onInitiatePayment && paymentMethod.onInitiatePayment.type === 'URL') {

                const userAgent = navigator.userAgent;
                const mobileDeviceRequest = userAgent.toLowerCase().includes("mobile");

                const data = {
                    "sessionId": this.sessionId,
                    "paymentMethodId": paymentMethod.id,
                    "mobileDeviceRequest": mobileDeviceRequest,
                }
                if (document.getElementById(componentSelected)) {
                    data.paymentDisplayComponent = componentSelected;
                }

                fetch(paymentMethod.onInitiatePayment.value, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.sessionToken,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
                    .then(function (response) {
                        console.log('create-payment response:', response);
                        if (response.status === 200) {
                            return response.json();
                        } else if (response.status === 401) {
                            throw new Error('Unauthorized');
                        } else {
                            return response.json().then(data => {
                                throw new Error(data.message);
                            });
                        }
                    })
                    .then(data => {
                        this.paymentId = data.paymentId;
                        if (data.type === 'REDIRECT') {
                            window.AltaPayUtils.redirect(data.url);
                        } else if (data.type === 'SCRIPT') {
                            const scriptElm = document.createElement('script');
                            scriptElm.src = data.url;
                            document.body.appendChild(scriptElm);
                            this.#checkStatus();
                        }
                        console.log('Payment Initialize Success:', data);
                        resolve(this);
                    })
                    .catch(error => {
                        const errorMessage = `Error initializing payment: ${error}`;
                        this.#handleJSError(errorMessage);
                        reject(errorMessage);
                    });
            } else {
                const errorMessage = 'Payment Initialize Error: unknown initialize payment method type';
                this.#handleJSError(errorMessage);
                reject(errorMessage)
            }
        });
    }

    #parseInitiatePaymentParams(params) {
        // for backward compatibility, please remove once we got monitoring around JS usage and confirmed not used
        if (typeof params === 'string' || params instanceof String) {
            return {
                paymentMethodId: params
            }
        } else {
            return params;
        }
    }

    #processStatus(data) {
        try {
            switch (data.status) {
                case PaymentStatus.SUCCEEDED:
                case PaymentStatus.PENDING:
                    if (this.callbacks
                        && this.callbacks.success
                        && this.callbacks.success.type === CallbackType.FUNCTION
                        && typeof this.callbacks.success.value === "function") {
                        window.dispatchEvent(new CustomEvent('closeEmbeddedPaymentWindow'));
                        this.#resetCheckStatus();
                        this.callbacks.success.value(this.altaPayFactory.getAltaPayEvent(EventType.PAYMENT, data.paymentId, data.shopOrderId, data.status));
                    }
                    break;
                case PaymentStatus.ERROR:
                case PaymentStatus.DECLINED:
                case PaymentStatus.FAILED:
                case PaymentStatus.CANCELLED:
                    if (this.callbacks
                        && this.callbacks.failure
                        && this.callbacks.failure.type === CallbackType.FUNCTION
                        && typeof this.callbacks.failure.value === "function") {
                        window.dispatchEvent(new CustomEvent('closeEmbeddedPaymentWindow'));
                        this.#resetCheckStatus();
                        this.callbacks.failure.value(this.altaPayFactory.getAltaPayEvent(EventType.PAYMENT, data.paymentId, data.shopOrderId, data.status, data.errorDetails?.customerErrorMessage, data.errorDetails));
                    }
                    break;
                default:
                    break;
            }
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    #checkStatus() {
        this.#checkStatusBySessionId()
    }

    #checkStatusBySessionId() {
        const that = this;
        this.checkStatus = setInterval(() => {
            if (that.sessionId) {
                const url = that.baseUrl + '/v1/api/session/' + that.sessionId;
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + that.sessionToken
                    }
                }).then(response => {
                    if (response.status !== 200) {
                        return Promise.reject('Received status: ' + response.status);
                    }
                    return response.json().then(data => {
                        if (data && data.activePayment) {
                            that.paymentId = data.activePayment.paymentId;
                            that.#processStatus(data.activePayment);
                            return Promise.resolve();
                        }
                    });
                }).catch((error) => {
                    that.#handleJSError(error);
                });
            } else {
                console.info("No payment session available");
            }
        }, that.paymentStatusCheckInterval)
    }

    onRenderPaymentMethodCallback(handler) {
        this.onRenderPaymentMethod = handler;
        return this;
    }

    addPaymentMethodSelectedEvent(callback) {
        this.onSelectedPaymentMethod.push(callback);
        return this;
    }
}